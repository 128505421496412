module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.9_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Les Arcs 1800","short_name":"Les Arcs 1800","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"fullscreen","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f855794def0c31234204210bfd98109c"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.12.3_gatsby@5.12.9_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.9_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.2.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
